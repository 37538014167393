var exports = {};
var own = {}.hasOwnProperty;
exports = stringify;

function stringify(value) {
  // Nothing.
  if (!value || typeof value !== "object") {
    return "";
  } // Node.


  if (own.call(value, "position") || own.call(value, "type")) {
    return position(value.position);
  } // Position.


  if (own.call(value, "start") || own.call(value, "end")) {
    return position(value);
  } // Point.


  if (own.call(value, "line") || own.call(value, "column")) {
    return point(value);
  } // ?


  return "";
}

function point(point) {
  if (!point || typeof point !== "object") {
    point = {};
  }

  return index(point.line) + ":" + index(point.column);
}

function position(pos) {
  if (!pos || typeof pos !== "object") {
    pos = {};
  }

  return point(pos.start) + "-" + point(pos.end);
}

function index(value) {
  return value && typeof value === "number" ? value : 1;
}

export default exports;